import m from 'mithril'
import classNames from 'classnames'
import {
    createTextMaskInputElement
} from 'text-mask-core'


const _defaultOptions = {
    type: 'text',
    label: false,
    classes: {},
    validate: () => {},
    mask: false,
    suffix: false
}

//輸入框
/**
 * params: {
 *  attrs,
 *  mask: {}, https://github.com/text-mask/text-mask/blob/master/componentDocumentation.md#readme
 *  suffix: mithril component 後置工具適用於刪除或新增
 *  append: mithril component 前置icon
 * }
 */
class TextBox {
    constructor(vnode) {
        const defaultOptions = {
            type: 'text'
        }
        this.options = vnode.attrs
        this.label = false
        this.active = false
        this.errors = []
        this.classes = {}

        //驗證
        this.validate = () => {}
        //遮罩
        this.mask = false
        //icon
        this.suffix = false

        this.append = false
        this.prepend = false


        if (vnode.attrs.hasOwnProperty('label')) {
            this.label = vnode.attrs.label
            // vnode.attrs.label = undefined
        }

        if (vnode.attrs.hasOwnProperty('placeholder')) {
            this.active = true
        }

        if (vnode.attrs.hasOwnProperty('validate')) {
            this.validate = vnode.attrs.validate
            vnode.attrs.validate = undefined
        }

        if (vnode.attrs.hasOwnProperty('helper')) {
            vnode.attrs.helper = undefined
        }

        if (vnode.attrs.hasOwnProperty('class')) {
            this.classes = vnode.attrs.class
            vnode.attrs.class = undefined
        }

        if (vnode.attrs.hasOwnProperty('mask')) {
            this.mask = vnode.attrs.mask
            vnode.attrs.mask = undefined
        }

        if (vnode.attrs.hasOwnProperty('suffix')) {
            this.suffix = vnode.attrs.suffix
            vnode.attrs.suffix = undefined
        }

        if (vnode.attrs.hasOwnProperty('append')) {
            this.append = vnode.attrs.append
            vnode.attrs.append = undefined
        }

        if (vnode.attrs.hasOwnProperty('prepend')) {
            this.prepend = vnode.attrs.prepend
            vnode.attrs.prepend = undefined
        }

        this.options = Object.assign(defaultOptions, vnode.attrs)
    }
    handleOptions(attrs) {

        const _options = Object.assign({}, attrs, {
            label: undefined,
            validate: undefined,
            helper: undefined,
            class: undefined,
            mask: undefined,
            suffix: undefined,
            append: undefined,
            prepend: undefined,
            error: undefined
        })

        return _options
    }
    hasError(error) {
        if (this.errors && this.errors.length > 0) {
            return this.errors
        }
        if (error) {
            return Array.isArray(error) ? error[0] : error
        }
        return false
    }
    initTextMask(dom) {
        this.textMaskInputElement = createTextMaskInputElement(Object.assign({}, {
            inputElement: dom
        }, this.mask))
    }
    onTextMaskInput(event) {
        this.textMaskInputElement.update(event.target.value)
    }
    oncreate(vnode) {
        if (this.mask) {
            const dom = vnode.dom.querySelector('input')
            this.initTextMask(dom)
        }
    }
    view(vnode) {
        return m('.md-input-field.md-form-control.w-100', {
            class: classNames({
                'md-input-append': this.append,
                'md-input-suffix': this.suffix
            })
        }, [
                (this.label) ? [
                    m('label', {
                        class: classNames({
                            'active': vnode.attrs.value || this.active
                        }),
                    }, this.label)
                ] : '',
            m('input.form-control', Object.assign({}, this.options, this.handleOptions(vnode.attrs), {
                class: classNames(this.classes, {
                    'invalid': this.hasError(vnode.attrs.error)
                }),
                style:{
                    fontSize:"1.25rem"
                },
                oninput: (e) => {
                    if (this.mask) {
                        this.onTextMaskInput(e)
                    }
                    vnode.attrs.oninput(e)
                    const errors = this.validate(e.target.value)
                    if (errors) {
                        this.errors = errors
                    } else {
                        this.errors = []
                    }
                },
                value: vnode.attrs.value
            })),
            (this.append) ? [
                m('.d-inline.append', [
                    this.append
                ])
            ] : '',
            (this.suffix) ? [
                m('.suffix', [
                    this.suffix
                ])
            ] : '',
            
            (vnode.attrs.helper || this.hasError(vnode.attrs.error)) ? [
                m('.helper-text', {
                    'data-error': this.hasError(vnode.attrs.error)
                }, [
                    vnode.attrs.helper
                ])
            ] : ''
        ])
    }
}

export default TextBox