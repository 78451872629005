import m from 'mithril'
import isMobile from 'lib/detect-mobile'
import Layout from 'app/pages/layout'
import * as Page from 'app/pages'
import {
    Account,
} from "app/models";

const root = document.body

window.isMobile = isMobile()

const managerRoutes = {
    '/': Layout(Page.DashboardPage),
    '/list': Layout(Page.ListPage),
    '/insert': Page.InsertPage,
    '/analization': Page.AnalizationPage,
    "/:404...": Page.ErrorPage,
}
const editorRoutes = {
    '/': Page.InsertPage,
    "/:404...": Page.ErrorPage,
}

const visitorRoutes = {
    '/': Layout(Page.DashboardPage),
    '/list': Layout(Page.ListPage),
    "/:404...": Page.ErrorPage,
}

const Routes = {
    "/:404...": Page.ErrorPage,
}

export function render() {
    Promise.all([Account.load(window.USER_ID)]).then((response) => 
    {
        // if (response[0].name == 'admin'){
        //     m.route(root, '/', managerRoutes)
            
        // }else if (response[0].name == 'visitor') {
        //     m.route(root, '/', visitorRoutes)
        // }else if (response[0].name == 'editor') {
        //     m.route(root, '/', editorRoutes)
        // }else{
        //     m.route(root, '/:404...', Routes)
        // }
        m.route(root, '/', managerRoutes)
    })
}