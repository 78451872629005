import m from 'mithril'
import moment from 'moment'
import BaseModel from './_base'

let _data = []

const constraints = {

}

var app = {}
app.xhrConfig = function (xhr) {
    xhr.setRequestHeader("Content-Type", "application/json")
}

  
class Tube extends BaseModel {

    constructor(args) {
        super(constraints)
        args = (args) ? args : {};
        this.id = args.id || 0;
        this.serial = args.serial || "";
        this.area = args.area || "";
        this.city = args.city || "";
        this.category = args.category || "";
        this.voter1 = args.voter1 || "";
        this.voter2 = args.voter2 || "";
        this.voter3 = args.voter3 || "";
        this.voter4 = args.voter4 || "";
        this.voter5 = args.voter5 || "";
        this.voter6 = args.voter6 || "";
        this.voter7 = args.voter7 || "";
        this.voter8 = args.voter8 || "";
        this.voter9 = args.voter9 || "";
        this.voter10 = args.voter10 || "";
        this.voter11 = args.voter11 || "";
        this.voter12 = args.voter12 || "";
        this.voter13 = args.voter13 || "";
        this.voter14 = args.voter14 || "";
        this.voter15 = args.voter15 || "";
        this.voter16 = args.voter16 || "";
        this.voter17 = args.voter17 || "";
        this.void = args.void || "";
        this.contact = args.contact || "";
        this.phone = args.phone || "";
        this.updated_at = moment(args.updated_at).format('Y-M-D') || moment().format('Y-M-D');
        this.created_at = moment(args.created_at).format('Y-M-D') || moment().format('Y-M-D');
    }

    static get data() {
        return _data;
    }

    static fetch() {
        Tube.loading = true
        return m.request({
            method: 'GET',
            url: `${window.BASE_URL}/api/tube`,            
        }).then((response) => {            
            Tube.loading = false
            return response
        })
    }

    static fetchTucheng() {
        Tube.loading = true
        return m.request({
            method: 'GET',
            url: `${window.BASE_URL}/api/fetchTucheng`,
        }).then((response) => {
            Tube.loading = false
            return response
        })
    }

    static fetchCategory() {
        Tube.loading = true
        return m.request({
            method: 'GET',
            url: `${window.BASE_URL}/api/fetchCategory/${window.category + "區"}`,
        }).then((response) => {
            Tube.loading = false
            return response
        })
    }

    static fetchCategoryCity() {
        Tube.loading = true
        return m.request({
            method: 'GET',
            url: `${window.BASE_URL}/api/fetchCategoryCity/${window.category + "區"}`,
        }).then((response) => {
            Tube.loading = false
            return response
        })
    }

    static fetchPhone(phone) {
        Tube.loading = true
        return m.request({
            method: 'GET',
            url: `${window.BASE_URL}/api/tubeByPhone/${phone}`,
        }).then((response) => {
            Tube.loading = false
            return response
        })
    }

    static fetch_record() {
        Tube.loading = true
        return m.request({
            method: 'GET',
            url: `${window.BASE_URL}/api/tube_fetchRecord`,
        }).then((response) => {
            Tube.loading = false
            return response
        })
    }

    save() {
        return m.request({
            method: 'POST',
            url: `${window.BASE_URL}/api/tube`,
            data: this,
        })
    }

    static load(serial) {
        Tube.loading = true

        return m.request({
            method: 'GET',
            url: `${window.BASE_URL}/api/tube/${serial}`,
        }).then((response) => {
            Tube.loading = false

            return response
        })
    }

    static delete(id) {
        return m.request({
            method: 'DELETE',
            url: `${window.BASE_URL}/api/tube/${id}`,
        })
    }    

    static changePassword(id, password) {
        return m.request({
            method: 'POST',
            url: `${window.BASE_URL}/api/changePassword`,
            data: {
                id: id,
                password:password
            },
        })
    }

    
}

export default Tube