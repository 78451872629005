import m from 'mithril'
import BaseModel from './_base'

let _data = []

const constraints = {

}

var app = {}
app.xhrConfig = function (xhr) {
    xhr.setRequestHeader("Content-Type", "application/json")
}

  
class Total extends BaseModel {

    constructor(args) {
        super(constraints)
        args = (args) ? args : {};
        this.id = args.id || 0;
        this.number = args.number || "";
        this.total = args.total || 0;
        
    }

    static get data() {
        return _data;
    }

    static fetch() {
        return m.request({
            method: 'GET',
            url: `${window.BASE_URL}/api/get_total`,
        }).then((response) => {
            return response
        })
    }  

    static fetchList() {
        return m.request({
            method: 'GET',
            url: `${window.BASE_URL}/api/get_total_list`,
        }).then((response) => {
            return response
        })
    }  

    static fetchBig() {
        return m.request({
            method: 'GET',
            url: `${window.BASE_URL}/api/get_total_big`,
        }).then((response) => {
            return response
        })
    }  

    

    
    
}

export default Total