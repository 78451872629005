import m from 'mithril'
import Footer from './partials/footer'
import Echo from 'laravel-echo';
import { Account } from 'app/models'

class LayoutComponent {
    oninit(state) {
        window.Echo = new Echo({
            broadcaster: "pusher",
            key: "766b0f7d3855ed6eb48b",   //測試版要改 0a78d288241c0a1d8eb4
            cluster: "ap3",
            forceTLS: false,
        })

        window.Echo.channel(`login`).listen('LoginPusher', (response) => {
            if (response.user.id == window.USER_ID) {
                swal({
                    title: "重複登入!",
                    text: "您的帳號已在別處登入!",
                    icon: "error",
                    showCancelButton: false,
                    showConfirmButton: false,
                    buttons: false,
                    timer: 2000,
                }).then((willDelete) => {
                    window.location.href = `${window.BASE_URL}/logout`
                });
            }
        });

    }

    checkRoute() {

        let _route = m.route.get()
        console.log(_route, "---------------")

        return /^\/site/i.test(_route)
    }
    setWidth(val) {
        console.log(val,"---------------")
        this.width = val
    }
    onremove(vnode) {
        window.Echo.disconnect()
    }

    view(vnode) {
        return [
            m('.d-flex.flex-column.justify-content-between', {
                style: {

                    backgroundImage: `url('${window.BASE_URL}/img/bg/background001.webp')`,
                    backgroundSize: "100% 100%",
                    height: (window.isMobile) ? "100%" : "100vh",
                    maxHeight: (window.isMobile) ? "100%" : "100vh",
                    minHeight: "100vh",
                }
            }, m(".w-100.h-100.d-flex.justify-content-between.flex-column", {
                style: {
                    background: "rgba(0,0,0,0.3)",
                    padding: (window.isMobile) ? "0px" : "10px 0px",
                }
            }, [
                vnode.children,
                m(Footer)
            ]),

            ),
        ]
    }
}

export default function (component, opts, auth) {
    return {
        onmatch: (args, requestedPath) => {
            // console.log(requestedPath)
            // let roles = []
            // roles.push("首頁")
            // const found = Menu.ManagementItems.find((item) => {
            //     console.log(item,"items")
            //     // if(requestedPath == "/"){
            //     //     return true
            //     // }
            //     // const match = item.regex.exec(requestedPath)
            //     // if (match) {
            //     //     return roles.includes(item.auth)
            //     // }
            //     return true
            // })
            // if (!found) {
            //     m.route.set("/404")
            // }
        },
        render: (vnode) => {
            return m(LayoutComponent, m(component))
        }
    }
}