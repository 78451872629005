import m from 'mithril'
import uuid from 'uuid'
import stream from 'mithril/stream'
import BaseModel from './_base'

let _data = []

const constraints = {

}

var app = {}
app.xhrConfig = function (xhr) {
    xhr.setRequestHeader("Content-Type", "application/json")
}

  
class Account extends BaseModel {

    constructor(args) {
        super(constraints)
        args = (args) ? args : {};
        this.id = args.id || 0
        this.email = args.email || ''
        this.name = args.name || ''
        this.type = args.type || ''
        this.permission = args.permission || []
        this.activated = args.activated || 0
        this.password = args.password || ''        
    }

    static get data() {
        return _data;
    }

    static fetch() {
        Account.loading = true
        return m.request({
            method: 'GET',
            url: `${window.BASE_URL}/api/account`,
        }).then((response) => {
            // _data = response
            Account.loading = false
            return response
        })
    }
    static load(id) {
        Account.loading = true
        return m.request({
            method: 'GET',
            url: `${window.BASE_URL}/api/account/${id}`
        }).then((response) => {
            _data = response
            Account.loading = false
            return response
        })
    }

    static save(data, data_id) {
        return m.request({
            method: 'POST',
            url: `${window.BASE_URL}/api/account`,
            body: [data, data_id]
        })
    }

    static update(id, name) {
        return m.request({
            method: 'POST',
            url: `${window.BASE_URL}/api/account/update`,
            body: [id, name]
        })
    }

    static updateKey(id, password) {
        return m.request({
            method: 'POST',
            url: `${window.BASE_URL}/api/account/updateKey`,
            body: [id, password]
        })
    }

    static updatePermission(id, permission) {
        return m.request({
            method: 'POST',
            url: `${window.BASE_URL}/api/account/updatePermission`,
            body: [id, permission]
        })
    }

    static create(data, data_id) {
        return m.request({
            method: 'POST',
            url: `${window.BASE_URL}/api/account`,
            body: [data, data_id]
        })
    }

    static remove(id) {
        return m.request({
            method: 'DELETE',
            url: `${window.BASE_URL}/api/account/${id}`
        })
    }
}

export default Account