import m from 'mithril'
import { Tube, Total, Account } from 'app/models'
import classNames from 'classnames'
import Echo from 'laravel-echo';


class ListPage {
    constructor() {
        this.colors = ['#00b7ee', '#89c997', '#f39800', '#eb6877', '#ffd200']
        this.voters = ["彭一書", "江怡臻", "黃永昌", "王正", "張昭隆", "洪佳君", "蘇泓欽", "曾進益", "歐金獅", "高乃芸", "廖宜琨", "呂家愷", "陳世榮", "林金結", "林銘仁", "簡元泰", "卓冠廷"]
        this._datas = []
        this.voter_total = []
        window.category = "土城"
        Tube.fetchCategory().then((response) => {
            this._datas = response
        })
        Total.fetchList().then((response) => {
            this.voter_total = response
        })
        this.select = []
        this.select_voter = []
        this.menu = true

    }

    oninit(state) {
        // document.addEventListener("visibilitychange", function () {
        //     if (document.visibilityState == "visible") {
        //         Tube.fetchCategory().then((response) => {
        //             this._datas = response
        //         })
        //         Total.fetchList().then((response) => {
        //             this.voter_total = response
        //         })
        //     }
        // });

        // window.Echo.channel(`admin`).listen('PusherTubeAlert', (tube) => {
        //     Tube.fetchCategory().then((response) => {
        //         this._datas = response
        //     })
        //     Total.fetchList().then((response) => {
        //         this.voter_total = response
        //     })

        // });
    }


    oncreate(vnode) {
        // if (!window.isMobile) {
        //     window.setTimeout(function () {
        //         m.route.set("/")
        //     }, 120000);
        // }
    }

    view() {
        return [
            m(".row.w-100.no-gutters", [
                m(".col-12.px-md-0.px-2.py-2.d-flex.justify-content-between.align-items-center", [
                    m(".badge.badge-light", {
                        style: {
                            fontSize: "1.25rem"
                        }
                    }, window.category + "區 全區票數"),
                    m(".d-flex", [
                        m(".btn.btn-light.d-flex.py-0.px-2.mx-2", {
                            onclick: (e) => {
                                e.preventDefault()
                                m.route.set("/")
                            }
                        },
                            m("", "返回總表")
                        ),
                        m(".btn.btn-light.d-flex.py-0.px-2.mx-2", {
                            onclick: (e) => {
                                e.preventDefault()
                                m.route.set("/analization")
                            }
                        },
                            m("", "前往分析")
                        ),
                        m(".btn.btn-light.d-flex.d-md-none.py-0", {
                            onclick: (e) => {
                                this.menu = !this.menu
                            }
                        },
                            m("", (this.menu) ? "-" : "+")
                        )
                    ]),
                    m(".d-md-flex.d-none", {

                    }, [
                        ["土城", "三峽", "樹林", "鶯歌"].map((_item) => {
                            return [
                                m(".btn.p-1.m-1", {
                                    class: classNames({
                                        'btn-warning': window.category == _item,
                                        'btn-light': window.category != _item,
                                    }),
                                    onclick: (e) => {
                                        e.preventDefault()
                                        this.select = []
                                        this.select_voter = []
                                        window.category = _item
                                        Tube.fetchCategory().then((response) => {
                                            this._datas = response
                                        })
                                    }
                                }, _item)
                            ]
                        })
                    ]),

                ]),
                (window.isMobile) ? [
                    (this.menu) ? [
                        m(".col-12.d-flex", {
                            style: {
                                overflow: "scroll"
                            }
                        }, [
                            ["土城", "三峽", "樹林", "鶯歌"].map((_item) => {
                                return [
                                    m(".btn.p-1.m-1", {
                                        class: classNames({
                                            'btn-warning': window.category == _item,
                                            'btn-light': window.category != _item,
                                        }),
                                        onclick: (e) => {
                                            e.preventDefault()
                                            this.select = []
                                            this.select_voter = []
                                            window.category = _item
                                            Tube.fetchCategory().then((response) => {
                                                this._datas = response
                                            })
                                        }
                                    }, _item)
                                ]
                            })
                        ]),
                        m(".col-12.d-flex", {
                            style: {
                                overflow: "scroll"
                            }
                        }, [
                            m(".btn.btn-outline-light.p-1.m-1", {
                                class: classNames({
                                    'active': this.select.length == Object.keys(this._datas).length,
                                }),
                                onclick: (e) => {
                                    e.preventDefault()
                                    if (this.select.length == Object.keys(this._datas).length) {
                                        this.select = []
                                    } else {
                                        this.select = Object.keys(this._datas)
                                    }
                                }
                            }, "全選"),
                            Object.keys(this._datas).map((_item) => {
                                return [
                                    m(".btn.p-1.m-1", {
                                        class: classNames({
                                            'btn-warning': this.select.includes(_item),
                                            'btn-light': !this.select.includes(_item),
                                        }),
                                        onclick: (e) => {
                                            e.preventDefault()
                                            if (this.select.includes(_item)) {
                                                this.select.splice(this.select.indexOf(_item), 1)
                                            } else {
                                                this.select.push(_item)
                                            }
                                        }
                                    }, _item.substr(0, 2))
                                ]
                            })
                        ]),
                        m(".col-12.d-flex", {
                            style: {
                                overflow: "scroll"
                            }
                        }, [
                            m(".btn.btn-outline-light.p-1.m-1", {
                                class: classNames({
                                    'active': this.select_voter.length == this.voters.length,
                                }),
                                onclick: (e) => {
                                    e.preventDefault()
                                    if (this.select_voter.length == this.voters.length) {
                                        this.select_voter = []
                                    } else {
                                        this.select_voter = this.voters.map((_item) => { return _item })
                                    }
                                }
                            }, "全選"),

                            this.voters.map((_item) => {
                                return [
                                    m(".btn.p-1.m-1", {
                                        class: classNames({
                                            'btn-warning': this.select_voter.includes(_item),
                                            'btn-light': !this.select_voter.includes(_item),
                                        }),
                                        onclick: (e) => {
                                            e.preventDefault()
                                            if (this.select_voter.includes(_item)) {
                                                this.select_voter.splice(this.select_voter.indexOf(_item), 1)
                                            } else {
                                                this.select_voter.push(_item)
                                            }
                                        }
                                    }, _item)
                                ]
                            })
                        ]),
                    ] : [],
                    m(".col-12.px-1.py-2",{
                        style:{
                            backgroundColor:"rgba(255,125,125,0.4)"
                        }
                    }, [
                        m(".row.border.no-gutters", [
                            m(".col-12.text-center.border", [
                                m("span.text-white", window.category + "區"),
                            ]),
                            this.select_voter.map((_voter) => {
                                if (this.voter_total.length > 0) {
                                    let cate_simbol = (window.category == "土城") ? "tu_total" : ((window.category == "樹林") ? "su_total" : ((window.category == "三峽") ? "xi_total" : "yi_total"))
                                    let found_index = this.voter_total.findIndex(object => {
                                        return object.number === _voter;
                                    });
                                    return [
                                        m(".col-4", [
                                            m("span.text-white", _voter + " : "),
                                            m("span.text-white", this.voter_total[found_index][cate_simbol]),
                                        ])
                                    ]
                                }
                            })
                        ])
                    ]),
                    this.select.map((_item) => {
                        let _open = 0
                        this._datas[_item].map((_check_tube) => {
                            if (_check_tube.voter1 != 0 || _check_tube.voter2 != 0 || _check_tube.voter3 != 0) {
                                _open += 1
                            }
                        }, 0)
                        return m(".col-12.px-1.py-2", [
                            m(".row.border.no-gutters", [
                                m(".col-12.text-center.border", [
                                    m("span.text-white", _item),
                                    m("span.text-white", " ( " + _open + " / " + this._datas[_item].length + ")")
                                ]),
                                this.select_voter.map((_voter) => {
                                    const _count = this._datas[_item].reduce((accumulator, _item) => {
                                        return accumulator + parseInt(_item["voter" + (this.voters.indexOf(_voter) + 1).toString()])
                                    }, 0)
                                    return [
                                        m(".col-4", [
                                            m("span.text-white", _voter + " : "),
                                            m("span.text-white", _count),
                                        ])
                                    ]
                                })


                            ])
                        ])
                    })
                ] : [
                    m(".col-12.px-0", [
                        m("table.table.table-hover.table-bordered.mb-0", [
                            m("thead", [
                                m("tr", {
                                    style: {
                                        backgroundColor: "#F2E7F7",
                                        fontSize: "1rem"
                                    }
                                }, [
                                    m("th[scope='col'][width='3%'].p-1.text-center", "候選人"),
                                    Object.keys(this._datas).map((_data) => {
                                        return [
                                            m("th[scope='col'].p-1.text-center", _data.substr(0, 2)),
                                        ]
                                    }),
                                    ["土城", "三峽", "樹林", "鶯歌"].map((_item) => {
                                        return m("th[scope='col'].p-1.text-center", {
                                            style: {
                                                backgroundColor: "#B87EBD",
                                            }
                                        }, _item)
                                    })
                                ])
                            ]),
                            m("tbody", [
                                this.voters.map((_voter, index) => {
                                    return [
                                        m("tr", {
                                            style: {
                                                color: "#FFFFFF"
                                            }
                                        }, [
                                            m("td[width='4.5%'].text-center.py-2.px-0", {
                                                style: {
                                                    fontSize: "1.25em",
                                                }
                                            }, _voter),

                                            Object.keys(this._datas).map((_data, _key_index) => {
                                                const _count = this._datas[_data].reduce((accumulator, _item) => {
                                                    return accumulator + parseInt(_item["voter" + (index + 1).toString()])
                                                }, 0)
                                                let _open = 0
                                                this._datas[_data].map((_item) => {
                                                    if (_item.voter1 != 0 || _item.voter2 != 0 || _item.voter3 != 0) {
                                                        _open += 1
                                                    }
                                                }, 0)
                                                // console.log(this._datas[_data])
                                                return [
                                                    m("td.py-2.px-0.text-center", {
                                                        style: {
                                                            // color: (_open == this._datas[_data].length) ? "#000000" : "#FFFFFF",
                                                            backgroundColor: (_open == this._datas[_data].length) ? "rgba(82,199,87,0.4)" : "",

                                                        }
                                                    }, _count),

                                                ]
                                            }),

                                            ["土城", "三峽", "樹林", "鶯歌"].map((_item) => {
                                                if (this.voter_total.length > 0 && this.voter_total[index]) {
                                                    let cate_simbol = (_item == "土城") ? "tu_total" : ((_item == "樹林") ? "su_total" : ((_item == "三峽") ? "xi_total" : "yi_total"))
                                                    return [
                                                        m("td.px-0.text-center", {
                                                            style: {
                                                                color: "#FFFFFF",
                                                                backgroundColor: "rgba(252,212,255,0.4)",
                                                            }
                                                        }, this.voter_total[index][cate_simbol]),
                                                    ]
                                                }
                                            })


                                        ])
                                    ]
                                })

                            ])
                        ]),
                    ]),
                ]
            ]),
        ]

    }
}

export default ListPage