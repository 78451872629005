import m from 'mithril'
import stream from 'mithril/stream'
import Echo from 'laravel-echo';

class Footer {
    constructor() {
        this.tube = []
    }
    oninit(state) {
        window.Echo.channel(`admin`).listen('PusherTubeAlert', (tube) => {
            this.tube.push(tube)
            if (this.tube.length == 1) {
                this.refreshId = setInterval(function () {
                    this.tube.splice(0, 1)
                    if (this.tube.length == 0) {
                        clearInterval(this.refreshId)
                    }
                    m.redraw()
                }.bind(this), 12050);
                m.redraw()
            }
        });
    }

    view({
        attrs
    }) {
        // console.log(this.tube)
        return [
            (this.tube.length > 0) ? [
                m(".w-100.d-flex.align-items-center.py-2", {
                    style: {
                        "white-space": "nowrap",
                        height: "50px",
                        backgroundColor: "yellow",
                    }
                },
                    [
                        m("marquee[SCROLLAMOUNT='15']", [

                        ], m("",[
                            m(".span",{
                                style:{
                                    fontSize:"1.25rem"
                                }
                            }, this.tube[0]['serial'] + "已開票 - " + this.tube[0]['tube']['city'] + this.tube[0]['tube']['category'])
                        ]))
                    ]
                )
            ] : [
                m(".footer.w-100", {
                    style: {
                        "white-space": "nowrap",
                    }
                },
                    [
                        m("span.float-right.text-white", {
                            style: {
                                fontSize: "0.5rem"
                            }
                        },
                            [
                                "Copyright ",
                                m.trust("&copy;"),
                                " 2022 Arron. All rights reserved.",
                            ]
                        )]
                )

            ]
        ]
    }
}

export default Footer