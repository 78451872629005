import m from 'mithril'
import classNames from 'classnames'
import stream from 'mithril/stream'
import { Total, Tube, Account } from 'app/models'
import Echo from 'laravel-echo';
import anime from 'animejs/lib/anime.es.js';


class DashboardPage {
    constructor() {
        this.voters = ["彭一書", "江怡臻", "黃永昌", "王正", "張昭隆", "洪佳君", "蘇泓欽", "曾進益", "歐金獅", "高乃芸", "廖宜琨", "呂家愷", "陳世榮", "林金結", "林銘仁", "簡元泰", "卓冠廷"]
        // this.refreshId = null
        this.tu_total = 0
        this.xi_total = 0
        this.su_total = 0
        this.yi_total = 0
        this.total = stream(0)
        this.voter_total = []

        this.abook = {
            total: 0,
            number: 1
        }

        Total.fetch().then((response) => {
            this.total = stream(0)
            this.voter_total = response
            this.voter_total.map((item, index) => {
                this.total(this.total() + item.total)
                if (item.id == 1) {
                    this.abook = {
                        total: item.total,
                        number: index + 1
                    }
                }
            })

        })
        this.tu_tubes = 0
        this.xi_tubes = 0
        this.su_tubes = 0
        this.yi_tubes = 0

        Tube.fetch().then((response) => {
            response.map((item) => {
                if (item.voter1 != 0 || item.voter2 != 0 || item.voter3 != 0 || item.voter4 != 0 || item.voter5 != 0) {
                    if (item.city == "土城區") {
                        this.tu_tubes += 1
                    }
                    if (item.city == "樹林區") {
                        this.su_tubes += 1
                    }
                    if (item.city == "三峽區") {
                        this.xi_tubes += 1
                    }
                    if (item.city == "鶯歌區") {
                        this.yi_tubes += 1
                    }
                }
            })
        })
    }

    oninit(state) {
        // document.addEventListener("visibilitychange", function () {
        //     if (document.visibilityState == "visible") {
        //         this.tu_total = 0
        //         this.xi_total = 0
        //         this.su_total = 0
        //         this.yi_total = 0
        //         this.total = stream(0)
        //         this.voter_total = []
        //         Total.fetch().then((response) => {
        //             this.total = stream(0)
        //             this.voter_total = response

        //             this.voter_total.map((item, index) => {
        //                 this.total(this.total() + item.total)
        //                 if (item.id == 1) {
        //                     this.abook = {
        //                         total: item.total,
        //                         number: index + 1
        //                     }
        //                 }
        //             })
        //         })

        //         this.tu_tubes = 0
        //         this.xi_tubes = 0
        //         this.su_tubes = 0
        //         this.yi_tubes = 0
        //         Tube.fetch().then((response) => {
        //             response.map((item) => {
        //                 if (item.voter1 != 0 || item.voter2 != 0 || item.voter3 != 0 || item.voter4 != 0 || item.voter5 != 0) {
        //                     if (item.city == "土城區") {
        //                         this.tu_tubes += 1
        //                     }
        //                     if (item.city == "樹林區") {
        //                         this.su_tubes += 1
        //                     }
        //                     if (item.city == "三峽區") {
        //                         this.xi_tubes += 1
        //                     }
        //                     if (item.city == "鶯歌區") {
        //                         this.yi_tubes += 1
        //                     }
        //                 }
        //             })
        //         })
        //         this.records = []
        //         Tube.fetch_record().then((response) => {
        //             this.records = response
        //         })
        //     }
        // });

        // window.Echo.channel(`admin`).listen('PusherTubeAlert', (tube) => {
        //     this.tu_total = 0
        //     this.xi_total = 0
        //     this.su_total = 0
        //     this.yi_total = 0
        //     this.total = stream(0)
        //     this.voter_total = []
        //     Total.fetch().then((response) => {
        //         this.total = stream(0)
        //         this.voter_total = response



        //         this.voter_total.map((item, index) => {
        //             this.total(this.total() + item.total)
        //             if (item.id == 1) {
        //                 this.abook = {
        //                     total: item.total,
        //                     number: index + 1
        //                 }
        //             }
        //         })

        //         anime({
        //             targets: '.rotate-change',
        //             scale: [
        //                 { value: 0.7, easing: 'easeOutSine', duration: 100 },
        //                 { value: 1, easing: 'easeInOutQuad', duration: 600 }
        //             ],
        //             // delay: anime.stagger(200, { grid: [14, 5], from: 'center' })
        //         });
        //     })

        //     this.tu_tubes = 0
        //     this.xi_tubes = 0
        //     this.su_tubes = 0
        //     this.yi_tubes = 0
        //     Tube.fetch().then((response) => {
        //         response.map((item) => {
        //             if (item.voter1 != 0 || item.voter2 != 0 || item.voter3 != 0 || item.voter4 != 0 || item.voter5 != 0) {
        //                 if (item.city == "土城區") {
        //                     this.tu_tubes += 1
        //                 }
        //                 if (item.city == "樹林區") {
        //                     this.su_tubes += 1
        //                 }
        //                 if (item.city == "三峽區") {
        //                     this.xi_tubes += 1
        //                 }
        //                 if (item.city == "鶯歌區") {
        //                     this.yi_tubes += 1
        //                 }
        //             }
        //         })
        //     })
        //     this.records = []
        //     Tube.fetch_record().then((response) => {
        //         this.records = response
        //     })

        // });
    }

    oncreate(vnode) {
        // if (!window.isMobile) {
        //     window.setTimeout(function () {
        //         m.route.set("/list")
        //     }, 240000);
        // }
        // this.refreshId = setInterval(function () {
        //     $('.number').each(function () {
        //         $(this).prop('Counter', 0).animate({
        //             Counter: $(this).text()
        //         }, {
        //             duration: 1000,
        //             easing: 'linear',
        //             step: function (now) {
        //                 $(this).text(Math.ceil(now));
        //             }
        //         });
        //     });
        // }.bind(this), 15000);
    }
    onremove(vnode) {
        // window.Echo.disconnect()
        // clearInterval(this.refreshId)
    }

    view() {
        return [
            m(".row.no-gutters.pt-1.pt-md-4.px-md-0.px-2", [
                m(".col-md-6.px-md-2.col-12.py-3.py-md-0.d-none.d-md-block", [
                    m(".card.text-white.h-100.px-md-5.py-3.px-1.img-animate", {
                        style: {
                            background: "rgba(0,0,0,0.5)",
                            borderRadius: (!window.isMobile) ? "2rem" : "0.5rem",
                        }
                    }, [
                        m(".card-body.p-0", [
                            m("span.card-title.d-inline-block.pb-1", {
                                style: {
                                    borderBottom: "2px solid #ffff00",
                                    fontSize: "1.75rem"
                                }
                            }, "票筒比例"),
                            m(".row.d-flex.justify-content-end.pt-2", [
                                m(".col-md.col-6.text-center.order-2.order-md-1.pt-4.pt-md-0", {
                                    style: {
                                        borderRight: "medium solid #5a767a",
                                    }
                                }, [
                                    m(".text-center", [
                                        m("span.d-block.font-weight-light.badge.badge-light", {
                                            style: {
                                                fontSize: "1.5rem"
                                            }
                                        }, "土城區"),
                                    ]),
                                    m(".text-center.pt-3", [
                                        m("span.font-weight-bolder.pt-3.number", {
                                            style: {
                                                fontSize: "3rem"
                                            }
                                        }, this.tu_tubes),
                                        m("span.font-weight-bolder", {
                                            style: {
                                                fontSize: "1.6rem"
                                            }
                                        }, " / 147"),
                                    ]),
                                ]),

                                m(".col-md.col-6.text-center.order-2.order-md-1.pt-4.pt-md-0", {
                                    style: {
                                        borderRight: "medium solid #5a767a",
                                    }
                                }, [
                                    m(".text-center", [
                                        m("span.d-block.font-weight-light.badge.badge-light", {
                                            style: {
                                                fontSize: "1.5rem"
                                            }
                                        }, "三峽區"),
                                    ]),
                                    m(".text-center.pt-3", [
                                        m("span.font-weight-bolder.pt-3.number", {
                                            style: {
                                                fontSize: "3rem"
                                            }
                                        }, this.xi_tubes),
                                        m("span.font-weight-bolder", {
                                            style: {
                                                fontSize: "1.6rem"
                                            }
                                        }, " / 72"),
                                    ]),
                                ]),

                                m(".col-md.col-6.text-center.order-2.order-md-1.pt-4.pt-md-0", {
                                    style: {
                                        borderRight: "medium solid #5a767a",
                                    }
                                }, [
                                    m(".text-center", [
                                        m("span.d-block.font-weight-light.badge.badge-light", {
                                            style: {
                                                fontSize: "1.5rem"
                                            }
                                        }, "樹林區"),
                                    ]),
                                    m(".text-center.pt-3", [
                                        m("span.font-weight-bolder.pt-3.number", {
                                            style: {
                                                fontSize: "3rem"
                                            }
                                        }, this.su_tubes),
                                        m("span.font-weight-bolder", {
                                            style: {
                                                fontSize: "1.6rem"
                                            }
                                        }, " / 112"),
                                    ]),
                                ]),


                                m(".col-md.col-6.text-center.order-2.order-md-1.pt-4.pt-md-0", [
                                    m(".text-center", [
                                        m("span.d-block.font-weight-light.badge.badge-light", {
                                            style: {
                                                fontSize: "1.5rem"
                                            }
                                        }, "鶯歌區"),
                                    ]),
                                    m(".text-center.pt-3", [
                                        m("span.font-weight-bolder.pt-3.number", {
                                            style: {
                                                fontSize: "3rem"
                                            }
                                        }, this.yi_tubes),
                                        m("span.font-weight-bolder", {
                                            style: {
                                                fontSize: "1.6rem"
                                            }
                                        }, " / 56"),
                                    ]),
                                ]),



                            ])
                        ]),


                    ])
                ]),
                m(".col-md-3.px-md-2.col-12.py-3.py-md-0.d-none.d-md-block", [
                    m(".card.text-white.h-100.px-md-5.py-3.px-1.img-animate", {
                        style: {
                            background: "rgba(0,0,0,0.5)",
                            borderRadius: (!window.isMobile) ? "2rem" : "0.5rem",
                        }
                    }, [
                        m(".card-body.p-0", [
                            m(".d-flex.justify-content-between.align-items-end", [
                                m("span.card-title.d-inline-block.pb-1", {
                                    style: {
                                        borderBottom: "2px solid #ffff00",
                                        fontSize: "2rem"
                                    }
                                }, "彭一書 得票數"),
                                m(".d-flex.justify-content-end", [
                                    m("span.d-inline-block.pb-1.badge.badge-warning", {
                                        style: {
                                            fontSize: "1.5rem"
                                        }
                                    }, "第 " + this.abook.number + " 名"),
                                ]),
                            ]),
                            m(".d-flex.justify-content-center.align-items-end", [
                                m("span.card-title.d-inline-block.font-weight-bolder.number", {
                                    style: {
                                        fontSize: "5rem"
                                    },
                                }, this.abook.total),
                                m("span.card-title.d-inline-block.pl-2.font-weight-bolder.pl-2", {
                                    style: {
                                        fontSize: "2rem"
                                    },
                                }, " 票"),
                            ]),

                        ]),


                    ])
                ]),
                m(".col-md-3.pr-md-4.col-12.d-none.d-md-block", [
                    m(".card.text-white.h-100.px-md-5.py-3.px-1.img-animate", {
                        style: {
                            background: "rgba(0,0,0,0.5)",
                            borderRadius: (!window.isMobile) ? "2rem" : "0.5rem",
                        }
                    }, [
                        m(".card-body.p-0", [
                            m("span.card-title.d-inline-block.pb-1", {
                                style: {
                                    borderBottom: "2px solid #F98F8F",
                                    fontSize: "1.75rem"
                                }
                            }, "已開票數"),

                            m(".d-flex.justify-content-center.align-items-end", [
                                m("span.card-title.d-inline-block.font-weight-bolder.number", {
                                    style: {
                                        fontSize: "5rem"
                                    }
                                }, ((((this.xi_tubes + this.tu_tubes + this.su_tubes + this.yi_tubes) / 387).toFixed(2)) * 100).toFixed(0)),
                                m("span.card-title.d-inline-block.pl-2.font-weight-bolder", {
                                    style: {
                                        fontSize: "2rem"
                                    }
                                }, " %"),
                            ])
                        ]),
                    ])
                ]),

                m(".col-md-12.px-md-2.col-12.pt-1.pt-md-3", [
                    m(".card.text-white.px-md-5.px-1.py-md-3.py-1", {
                        style: {
                            background: "rgba(0,0,0,0.5)",
                            borderRadius: (!window.isMobile) ? "2rem" : "0.5rem",
                        }
                    }, [
                        m('.row', [
                            m('.col-md-12.d-flex.flex-column.justify-content-between', [
                                m(".card-body.p-0.d-flex.justify-content-between.align=items-center", {
                                    style: {
                                        borderBottom: "2px solid #FCCD83",
                                    }
                                }, [
                                    m("span.card-title.d-inline-block.pb-md-1.pb-0.mb-md-0", {
                                        style: {
                                            fontSize: (window.isMobile) ? "1.2rem" : "1.75rem"
                                        }
                                    }, "新北市第八選區議員即時開票"),
                                    m(".d-inline-block.justify-content-center.d-md-none", [
                                        m("span.card-title.d-inline-block.pb-1.font-weight-bolder.number", {
                                            style: {
                                                fontSize: "1rem"
                                            }
                                        }, ((((this.xi_tubes + this.tu_tubes + this.su_tubes + this.yi_tubes) / 387).toFixed(2)) * 100).toFixed(0)),
                                        m("span.card-title.d-inline-block.pb-1.font-weight-bolder", {
                                            style: {
                                                fontSize: "1rem"
                                            }
                                        }, " %"),
                                    ])
                                ]),
                                m(".d-flex.justify-content-end.align-items-center.py-2", [
                                    // m(".btn.btn-light.d-flex.d-md-none.py-0.px-2.mx-2", {
                                    //     onclick: (e) => {
                                    //         e.preventDefault()
                                    //         swal({
                                    //             text: '請輸入新密碼',
                                    //             content: "input",
                                    //             button: {
                                    //                 text: "確認!",
                                    //                 closeModal: false,
                                    //             },
                                    //         }).then(name => {
                                    //             if (!name) throw null;
                                    //             Tube.changePassword(window.USER_ID, name).then((_response) => {
                                    //                 swal({
                                    //                     title: "修改成功!",
                                    //                     text: "下次登入請使用新密碼!",
                                    //                     icon: "success",
                                    //                     showCancelButton: false,
                                    //                     showConfirmButton: false,
                                    //                     buttons: false,
                                    //                     timer: 1500,
                                    //                 }).then((willDelete) => {
                                    //                     swal.close()
                                    //                 });
                                    //             })

                                    //         })
                                    //     }
                                    // },
                                    //     m("", "修改密碼")
                                    // ),
                                    m(".btn.btn-light.d-flex.py-0.px-2.mx-2", {
                                        onclick: (e) => {
                                            e.preventDefault()
                                            m.route.set("/list")
                                        }
                                    },
                                        m("", "前往里別")
                                    ),

                                    m(".btn.btn-light.d-flex.py-0.px-2.mx-2", {
                                        onclick: (e) => {
                                            e.preventDefault()
                                            m.route.set("/analization")
                                        }
                                    },
                                        m("", "前往分析")
                                    ),
                                ]),

                                m(".row.w-100.d-flex.justify-content-start.no-gutters.pt-0.pt-md-2", {

                                }, [
                                    this.voter_total.map((_voter, index) => {
                                        return [
                                            m(".pt-md-2.pb-md-2.py-1", {
                                                class: classNames({
                                                    'col': !window.isMobile,
                                                    'col-12': window.isMobile
                                                }),
                                                style: {
                                                    minWidth: (!window.isMobile) ? "10%" : "",
                                                    maxWidth: (!window.isMobile) ? "10%" : "",
                                                    border: (window.isMobile) ? ((_voter.id == 1) ? "1px solid #ffe500" : "") : ((_voter.id == 1) ? "2px solid #ffe500" : ""),
                                                    borderRadius: (window.isMobile) ? "20px" : "2rem",
                                                    backgroundColor: (window.isMobile) ? ((index > 9) ? "rgba(255,255,255,0.2)" : "") : ""
                                                }
                                            }, [
                                                m(".row.w-100.d-flex.justify-content-center.no-gutters.py-md-1", [
                                                    m(".col-1.d-flex.d-md-none.justify-content-center.align-items-center", [
                                                        m("span.d-block.d-md-none", { style: { color: "", fontSize: "2rem" } }, index + 1),
                                                    ]),
                                                    m(".col-md-12.col-3.badge.d-flex.justify-content-end.justify-content-md-center", {
                                                        class: classNames({
                                                            'badge_customer': !window.isMobile,
                                                        }),
                                                    }, [

                                                        m(".circle.rounded-circle.img-animate-plus.rotate-change", {
                                                            style: {
                                                                height: (!window.isMobile) ? "120px" : "50px",
                                                                width: (!window.isMobile) ? "120px" : "50px",
                                                                maxHeight: (!window.isMobile) ? "120px" : "50px",
                                                                maxWidth: (!window.isMobile) ? "120px" : "50px",
                                                                "background-image": `url("${window.BASE_URL}/img/candidate/${_voter.id}.webp")`,
                                                                "background-repeat": "no-repeat",
                                                                "background-position": "center",
                                                                "background-size": "cover",
                                                                border: "3px solid #8ebda0",

                                                            }
                                                        }),
                                                        m(".ribbon.goldfont", {
                                                            style: {
                                                                display: (!window.isMobile) ? "block" : "none"
                                                            }
                                                        }, index + 1)
                                                    ]),
                                                    m(".col-7.d-flex.d-md-none.justify-content-between.align-items-center", [
                                                        // m("span.font-weight-light", { style: { fontSize: (window.isMobile) ? "0.6rem" : "0.8rem" } }, _voter.id + " 號 "),
                                                        m("span.pl-4", {
                                                            style: {
                                                                fontSize: "1.5rem"
                                                            }
                                                        }, _voter.number),
                                                        m(".arial_num.font-weight-bolder.number", {
                                                            style: {
                                                                fontSize: "1.5rem"
                                                            }
                                                        }, _voter.total),
                                                    ]),
                                                    m(".col-md-12.d-md-flex.justify-content-center.pt-md-3.pt-0.d-none", [
                                                        // m("span.font-weight-light", { style: { fontSize: (window.isMobile) ? "0.6rem" : "0.8rem" } }, _voter.id + " 號 "),
                                                        m("span.pl-2", { style: { fontSize: (window.isMobile) ? "1.4rem" : "1.6rem" } }, _voter.number),
                                                    ]),
                                                    m(".col-md-12.d-md-flex.justify-content-center.d-none.align-items-end", {
                                                        style: {
                                                            lineHeight: "3rem"
                                                        }
                                                    }, [
                                                        m(".arial_num.font-weight-bolder.number", {
                                                            style: {
                                                                fontSize: "2.5rem"
                                                            }
                                                        }, _voter.total),
                                                        m(".arial_num.font-weight-bolder.pl-2", {
                                                            style: {
                                                                fontSize: "1.5rem"
                                                            }
                                                        }, " 票"),
                                                    ]),
                                                    m(".col-md-12.d-md-flex.justify-content-center.d-none", [
                                                        m("span.d-block.arial_num", {
                                                            style: {
                                                                fontSize: "1.5rem"
                                                            }
                                                        }, (this.total() != 0) ? ((_voter.total / this.total()).toFixed(4) * 100).toFixed(1) + " %" : "0%"),
                                                    ]),
                                                ])
                                            ])
                                        ]
                                    })
                                ]),




                            ]),
                        ])

                    ])
                ]),

            ]),

        ]
    }
}

export default DashboardPage