
import m from 'mithril'
import echarts from 'echarts'
import stream from 'mithril/stream';

class BarGauge {
    constructor(vnode) {

        this.option = {

            dataset: {
                dimensions: ['name', '民進黨', '國民黨'],
                source: [
                    { name: '全區', '民進黨': 0, '國民黨': 0 },
                    { name: '土城區', '民進黨': 0, '國民黨': 0 },
                    { name: '樹林區', '民進黨': 0, '國民黨': 0 },
                    { name: '三峽區', '民進黨': 0, '國民黨': 0 },
                    { name: '鶯歌區', '民進黨': 0, '國民黨': 0 },
                ]
            },
            xAxis: { type: 'category' },
            yAxis: {},
            series: [{
                type: 'bar', color: '#339911',
                label: {
                    position: 'top',
                    distance: 15,
                    show: true,
                    formatter: ["{c}"],
                    backgroundColor: '#339911',
                    color: '#fff',
                    borderRadius: 10,
                    padding: 5,
                    fontSize:18,
                }
            }, {
                type: 'bar', color: '#0B63B6',
                label: {
                    position: 'top',
                    distance: 15,
                    show: true,
                    formatter: ["{c}"],
                    backgroundColor: '#0B63B6',
                    color: '#fff',
                    borderRadius: 10,
                    padding: 5,
                    fontSize:18,
                }
            }],
        }
    }

    onupdate(vnode) {
        const canvas = vnode.dom.querySelector('.widget_canvas')
        const chart = echarts.init(canvas, 'dark')
        this.option.dataset.source = [
            { name: '全區', '民進黨': 0, '國民黨': 0 },
            { name: '土城區', '民進黨': 0, '國民黨': 0 },
            { name: '樹林區', '民進黨': 0, '國民黨': 0 },
            { name: '三峽區', '民進黨': 0, '國民黨': 0 },
            { name: '鶯歌區', '民進黨': 0, '國民黨': 0 }
        ]
        vnode.attrs.datas.map((_item) => {
            if (vnode.attrs.blue().includes(_item.id)) {
                this.option.dataset.source[0].國民黨 += _item.total
                this.option.dataset.source[1].國民黨 += _item.tu_total
                this.option.dataset.source[2].國民黨 += _item.su_total
                this.option.dataset.source[3].國民黨 += _item.xi_total
                this.option.dataset.source[4].國民黨 += _item.yi_total
            }
            if (vnode.attrs.green().includes(_item.id)) {
                this.option.dataset.source[0].民進黨 += _item.total
                this.option.dataset.source[1].民進黨 += _item.tu_total
                this.option.dataset.source[2].民進黨 += _item.su_total
                this.option.dataset.source[3].民進黨 += _item.xi_total
                this.option.dataset.source[4].民進黨 += _item.yi_total
            }

        })
        chart.setOption(this.option, true);

        window.onresize = function () {
            chart.resize();
        };
    }
    view(vnode) {
        return m('.mx-auto', {
            style: {
                position: 'relative'
            }
        }, [
            m('.widget_canvas', {
                style: {
                    width: '100%',
                    height: '400px'
                },
                onclick: (e) => {
                    e.preventDefault()
                }
            }),
        ])
    }
}
export default BarGauge