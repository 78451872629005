import m from 'mithril'
import { Tube } from 'app/models'
import {
    Paging,
    Dialog,
    TextBox
} from 'components'

class InsertPage {
    constructor(vnode) {
        this.model = new Tube()
        this.voters = ["彭一書", "江怡臻", "黃永昌", "王正", "張昭隆", "洪佳君", "蘇泓欽", "曾進益", "歐金獅", "高乃芸", "廖宜琨", "呂家愷", "陳世榮", "林金結", "林銘仁", "簡元泰", "卓冠廷"]
        this.tu_tube_serials = []
        this.xi_tube_serials = []
        this.su_tube_serials = []
        this.yi_tube_serials = []
        this._select_tube = ""
        this._datas = []
        this.phone = ""
        this.check = false
        this.error = false
    }
    save() {

        swal({
            title: "回報成功!",
            text: "已成功回報，請確認是否所有投開票所接完成回報!!",
            icon: "success",
            buttons: false,
            timer: 1000,
        })
        this.model.save().then(() => {
            this._select_tube = ""
            this._datas = []
            Tube.fetchPhone(this.phone).then((response) => {
                this.tu_tube_serials = []
                this.xi_tube_serials = []
                this.su_tube_serials = []
                this.yi_tube_serials = []
                response.map((item) => {
                    if (item.city == "土城區") {
                        this.tu_tube_serials.push(item.serial)
                    }
                    if (item.city == "樹林區") {
                        this.su_tube_serials.push(item.serial)
                    }
                    if (item.city == "三峽區") {
                        this.xi_tube_serials.push(item.serial)
                    }
                    if (item.city == "鶯歌區") {
                        this.yi_tube_serials.push(item.serial)
                    }
                    if (item.voter1 != 0 || item.voter2 != 0 || item.voter3 != 0 || item.voter4 != 0 || item.voter5 != 0) {
                        this._datas.push(item.serial)
                    }
                })
                if (response.length > 0) {
                    this.check = true
                }
                else {
                    this.error = true
                }
            })
        })
    }
    view() {
        return [

            m('.d-flex.flex-column.justify-content-between', {
                style: {
                    backgroundImage: `url('${window.BASE_URL}/img/bg/background001.jpg')`,
                    // backgroundSize: "100% 100%",
                    // height: (window.isMobile) ? "100%" : "100vh",
                    // maxHeight: (window.isMobile) ? "100%" : "100vh",
                    minHeight: "100vh",
                }
            }, m(".w-100.h-100.d-flex.justify-content-between.flex-column", {
                style: {
                    // background: "rgba(0,0,0,0.2)",
                    padding: (window.isMobile) ? "0px" : "10px 0px",
                }
            }, [
                (!this.check) ? [
                    m(".row.no-gutters.d-flex.justify-content-center", [
                        m(".col-md-3.col-8.pt-5", [
                            m(".pb-2",
                                m("span.text-white", "請輸入手機號碼"),
                            ),
                            m(TextBox, {
                                oninput: (e) => {
                                    this.phone = e.target.value
                                },
                                type: "tel",
                                value: this.phone,
                            }),
                            m(".pt-4.d-flex.justify-content-center",
                                m("button.btn.btn-success.px-3.mx-3", {
                                    onclick: (e) => {
                                        if (this.phone == "" || this.phone == null) {
                                            swal({
                                                title: "請輸入電話!",
                                                icon: "error",
                                                showCancelButton: false,
                                                showConfirmButton: false,
                                                buttons: false,
                                                timer: 1500,
                                            })
                                            return false
                                        }
                                        Tube.fetchPhone(this.phone).then((response) => {
                                            this.tu_tube_serials = []
                                            this.xi_tube_serials = []
                                            this.su_tube_serials = []
                                            this.yi_tube_serials = []
                                            response.map((item) => {
                                                if (item.city == "土城區") {
                                                    this.tu_tube_serials.push(item.serial)
                                                }
                                                if (item.city == "樹林區") {
                                                    this.su_tube_serials.push(item.serial)
                                                }
                                                if (item.city == "三峽區") {
                                                    this.xi_tube_serials.push(item.serial)
                                                }
                                                if (item.city == "鶯歌區") {
                                                    this.yi_tube_serials.push(item.serial)
                                                }
                                                if (item.voter1 != 0 || item.voter2 != 0 || item.voter3 != 0 || item.voter4 != 0 || item.voter5 != 0) {
                                                    this._datas.push(item.serial)
                                                }
                                            })
                                            if (response.length > 0) {
                                                this.check = true
                                            }
                                            else {
                                                this.error = true
                                            }
                                        })
                                    }
                                }, "確認"),
                            ),
                            (this.error) ? [
                                m(".pt-2",
                                    m("span", {
                                        style: {
                                            fontSize: "1.5rem",
                                            color: "#FF0000"
                                        }
                                    }, "此手機號碼尚未註冊！"),
                                ),
                            ] : ""
                        ]),

                    ])
                ] : [
                    (!Tube.loading) ? [
                        (this._select_tube != "") ? [
                            m(".row.no-gutters.d-flex.justify-content-center", [
                                m(".col-12.col-md-6.pt-0.pt-md-5", [
                                    m(".card", {
                                        style: {
                                            background: "rgba(0,0,0,0.5)",
                                        }
                                    }, [
                                        m(".card-body", [
                                            m("h5.card-title.text-white", this.model.serial + " - " + this.model.city + this.model.category),
                                            m("p.card-text.text-white", this.model.contact + " - " + this.model.phone),
                                            m(".row.no-gutters", [

                                                this.voters.map((_voter, _index) => {
                                                    return [
                                                        m(".col-12.col-md-4.px-3.py-md-2", [
                                                            m(".form-group", [
                                                                m("label.text-white.mb-1", {
                                                                    style: {
                                                                        fontSize: (!window.isMobile) ? "1.25rem" : ""
                                                                    }
                                                                }, (_index + 1) + "." + _voter),
                                                                [
                                                                    m(TextBox, {
                                                                        oninput: (e) => {
                                                                            this.model["voter" + (_index + 1)] = e.target.value
                                                                        },
                                                                        type: "tel",
                                                                        // pattern:"\d*",
                                                                        value: this.model["voter" + (_index + 1)],
                                                                    })
                                                                ]])
                                                        ]),
                                                    ]
                                                }),


                                                m(".col-12.col-md-4.px-3", [
                                                    m(".form-group", [
                                                        m("label.text-white.mb-1", {
                                                            style: {
                                                                fontSize: (!window.isMobile) ? "1.25rem" : ""
                                                            }
                                                        }, "廢票"),
                                                        [
                                                            m(TextBox, {
                                                                oninput: (e) => {
                                                                    this.model.void = e.target.value
                                                                },
                                                                type: "tel",
                                                                // pattern:"\d*",
                                                                value: this.model.void,
                                                            })
                                                        ]])
                                                ]),
                                            ]),

                                            m(".row.no-gutters.d-flex.justify-content-end.pt-4", [
                                                m(".col-md-4.col-12.d-flex.justify-content-between", [
                                                    m("button.btn.btn-info.px-3.mx-3", {
                                                        onclick: (e) => {
                                                            this._select_tube = ""
                                                            this._datas = []
                                                            Tube.fetch().then((response) => {
                                                                response.map((item) => {
                                                                    if (item.voter1 != 0 || item.voter2 != 0 || item.voter3 != 0 || item.voter4 != 0 || item.voter5 != 0) {
                                                                        this._datas.push(item.serial)
                                                                    }
                                                                })
                                                            })
                                                        }
                                                    }, "返回"),
                                                    m("button.btn.btn-success.px-3.mx-3", {
                                                        onclick: (e) => {
                                                            e.preventDefault()
                                                            let _check_point = true
                                                            for (let index = 0; index < 17; index++) {
                                                                if (this.model["voter" + (index + 1).toString()] >= 2000) {
                                                                    _check_point = false
                                                                }

                                                            }
                                                            if (!_check_point) {
                                                                swal({
                                                                    title: "請檢查票數",
                                                                    text: "票數不得大於2000票!",
                                                                    icon: "error",
                                                                    buttons: false,
                                                                    timer: 2500,
                                                                })
                                                                return false
                                                            } else {


                                                                swal({
                                                                    title: "請確認以下資訊是否有誤",
                                                                    content: {
                                                                        element: 'div',
                                                                        attributes: {
                                                                            innerHTML: `
                                                                                <div class="row">
                                                                                <div class="col-12 pb-2"><span class="h5" style="font-size:1rem">${this.model.serial + " - " + this.model.city + this.model.category}</span></div>
                                                                                <div class="col-12 py-1"><span class="h5" style="font-size:1rem">1.彭一書:${this.model.voter1}</span></div>
                                                                                <div class="col-12 py-1"><span class="h5" style="font-size:1rem">2.江怡臻:${this.model.voter2}</span></div>
                                                                                <div class="col-12 py-1"><span class="h5" style="font-size:1rem">3.黃永昌:${this.model.voter3}</span></div>
                                                                                <div class="col-12 py-1"><span class="h5" style="font-size:1rem">4.王正:${this.model.voter4}</span></div>
                                                                                <div class="col-12 py-1"><span class="h5" style="font-size:1rem">5.張昭隆:${this.model.voter5}</span></div>
                                                                                <div class="col-12 py-1"><span class="h5" style="font-size:1rem">6.洪佳君:${this.model.voter6}</span></div>
                                                                                <div class="col-12 py-1"><span class="h5" style="font-size:1rem">7.蘇泓欽:${this.model.voter7}</span></div>
                                                                                <div class="col-12 py-1"><span class="h5" style="font-size:1rem">8.曾進益:${this.model.voter8}</span></div>
                                                                                <div class="col-12 py-1"><span class="h5" style="font-size:1rem">9.歐金獅:${this.model.voter9}</span></div>
                                                                                <div class="col-12 py-1"><span class="h5" style="font-size:1rem">10.高乃芸:${this.model.voter10}</span></div>
                                                                                <div class="col-12 py-1"><span class="h5" style="font-size:1rem">11.廖宜琨:${this.model.voter11}</span></div>
                                                                                <div class="col-12 py-1"><span class="h5" style="font-size:1rem">12.呂家愷:${this.model.voter12}</span></div>
                                                                                <div class="col-12 py-1"><span class="h5" style="font-size:1rem">13.陳世榮:${this.model.voter13}</span></div>
                                                                                <div class="col-12 py-1"><span class="h5" style="font-size:1rem">14.林金結:${this.model.voter14}</span></div>
                                                                                <div class="col-12 py-1"><span class="h5" style="font-size:1rem">15.林銘仁:${this.model.voter15}</span></div>
                                                                                <div class="col-12 py-1"><span class="h5" style="font-size:1rem">16.簡元泰:${this.model.voter16}</span></div>
                                                                                <div class="col-12 py-1"><span class="h5" style="font-size:1rem">17.卓冠廷:${this.model.voter17}</span></div>
                                                                                <div class="col-12 py-1"><span class="h5" style="font-size:1rem">廢票:${this.model.void}</span></div>
                                                                                </div>`,
                                                                        },
                                                                    },
                                                                    icon: "warning",
                                                                    dangerMode: true,
                                                                    buttons: ["取消", "確認"]
                                                                })
                                                                    .then((willDelete) => {
                                                                        if (willDelete) {
                                                                            this.save()
                                                                        } else {
                                                                            swal("您的資料未被儲存");
                                                                        }
                                                                    });

                                                            }

                                                        }
                                                    }, "送出"),
                                                ]),

                                            ])
                                        ])
                                    ]),
                                ]),



                            ])
                        ] : [
                            m(".row.no-gutters", [
                                m(".col-12.d-flex.justify-content-end", [
                                    m("button.btn.btn-warning.font-weight-bolder.text-white", {
                                        style: {
                                            fontSize: "1rem"
                                        },
                                        onclick: (e) => {
                                            this.check = false
                                        }
                                    }, "重新輸入電話號碼"),
                                ]),

                                (this.tu_tube_serials.length > 0) ? [
                                    m(".col-md-4.col-12.px-1", [
                                        m("span.font-weight-bolder.text-white", {
                                            style: {
                                                fontSize: "1.5rem"
                                            }
                                        }, "土城區"),
                                        m(".row.no-gutters", [
                                            this.tu_tube_serials.map((tube) => {
                                                return [
                                                    m(".col.p-1.text-center", [
                                                        m("button.btn.w-100.rounded-0", {
                                                            class: (this._datas.includes(tube.toString())) ? "" : "btn-outline-light",
                                                            style: {
                                                                backgroundColor: (this._datas.includes(tube.toString())) ? "#89c997" : "",
                                                                color: (this._datas.includes(tube.toString())) ? "#FFF" : "",
                                                            },
                                                            type: "button",
                                                            onclick: (e) => {
                                                                this._select_tube = tube
                                                                Tube.load(this._select_tube).then((response) => {
                                                                    this.model = new Tube(response)
                                                                })
                                                            }
                                                        }, tube)
                                                    ])
                                                ]
                                            })
                                        ])
                                    ]),
                                ] : "",

                                (this.xi_tube_serials.length > 0) ? [
                                    m(".col-md-3.col-12.px-1", [
                                        m("span.font-weight-bolder.text-white", {
                                            style: {
                                                fontSize: "1.5rem"
                                            }
                                        }, "三峽區"),
                                        m(".row.no-gutters", [
                                            this.xi_tube_serials.map((tube) => {
                                                return [
                                                    m(".col.p-1.text-center", [
                                                        m("button.btn.w-100.rounded-0", {
                                                            class: (this._datas.includes(tube.toString())) ? "" : "btn-outline-light",
                                                            style: {
                                                                backgroundColor: (this._datas.includes(tube.toString())) ? "#89c997" : "",
                                                                color: (this._datas.includes(tube.toString())) ? "#FFF" : "",
                                                            },
                                                            type: "button",
                                                            onclick: (e) => {
                                                                this._select_tube = tube
                                                                Tube.load(this._select_tube).then((response) => {
                                                                    this.model = new Tube(response)
                                                                })
                                                            }
                                                        }, tube)
                                                    ])
                                                ]
                                            })
                                        ])
                                    ]),
                                ] : "",
                                (this.su_tube_serials.length > 0) ? [

                                    m(".col-md-3.col-12.px-1", [
                                        m("span.font-weight-bolder.text-white", {
                                            style: {
                                                fontSize: "1.5rem"
                                            }
                                        }, "樹林區"),
                                        m(".row.no-gutters", [
                                            this.su_tube_serials.map((tube) => {
                                                return [
                                                    m(".col.p-1.text-center", [
                                                        m("button.btn.w-100.rounded-0", {
                                                            class: (this._datas.includes(tube.toString())) ? "" : "btn-outline-light",
                                                            style: {
                                                                backgroundColor: (this._datas.includes(tube.toString())) ? "#89c997" : "",
                                                                color: (this._datas.includes(tube.toString())) ? "#FFF" : "",
                                                            },
                                                            type: "button",
                                                            onclick: (e) => {
                                                                this._select_tube = tube
                                                                Tube.load(this._select_tube).then((response) => {
                                                                    this.model = new Tube(response)
                                                                })
                                                            }
                                                        }, tube)
                                                    ])
                                                ]
                                            })
                                        ])
                                    ]),
                                ] : "",
                                (this.yi_tube_serials.length > 0) ? [

                                    m(".col-md-2.col-12.px-1", [
                                        m("span.font-weight-bolder.text-white", {
                                            style: {
                                                fontSize: "1.5rem"
                                            }
                                        }, "鶯歌區"),
                                        m(".row.no-gutters", [
                                            this.yi_tube_serials.map((tube) => {
                                                return [
                                                    m(".col.p-1.text-center", [
                                                        m("button.btn.w-100.rounded-0", {
                                                            class: (this._datas.includes(tube.toString())) ? "" : "btn-outline-light",
                                                            style: {
                                                                backgroundColor: (this._datas.includes(tube.toString())) ? "#89c997" : "",
                                                                color: (this._datas.includes(tube.toString())) ? "#FFF" : "",
                                                            },
                                                            type: "button",
                                                            onclick: (e) => {
                                                                this._select_tube = tube
                                                                Tube.load(this._select_tube).then((response) => {
                                                                    this.model = new Tube(response)
                                                                })
                                                            }
                                                        }, tube)
                                                    ])
                                                ]
                                            })
                                        ])
                                    ]),
                                ] : "",


                            ])
                        ]
                    ] : [
                        m(".row.no-gutters", [
                            m(".col-12", [
                                m("span.font-weight-bolder.text-white", {
                                    style: {
                                        fontSize: "1.5rem"
                                    }
                                }, "資料獲取中...")
                            ])
                        ])
                    ]
                ]

            ]),

            ),

        ]
    }
}

export default InsertPage